<template>
  <b-container v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h2 style="margin-top: 4rem; margin-bottom: 1.3rem;">Parken</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <img class="image" src="@/assets/pictures/step5.jpg" alt="tutorial" style="width: 100%; height: 100%;"/>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p style="margin-top: 1.3rem;">Zum Parken schalte am Lenker in den Parkmodus, ziehe den linken Bremshebel möglichst weit an den Lenker heran und drücke den Feststellhebel nach außen.<br>Hierfür muss der Lenker in einer nach vorne ausgerichteten Position stehen.</p>
        <p style="margin-bottom: 4rem;">Überprüfe durch leichtes Vorwärtsschieben, dass die Vorderräder tatsächlich blockiert sind.</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row class="fixed-bottom pageIndicatorPos">
      <b-col cols="2"/>
      <b-col cols="8">
        <PageIndicator route="bikeTutorial" :max="6" :pos="5"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
      <b-row>
      <NextButton text="Weiter" next @click="$router.push('/biketutorial/6')" />
    </b-row>
  </b-container>
</template>

<script>
import PageIndicator from '../components/PageIndicator';
import NextButton from '../components/NextButton';
  export default {
    name: 'BikeTutorial5',
    components: {
      PageIndicator,
      NextButton,
    },
    methods: {
      swipeLeft() {
        this.$router.push('/bikeTutorial/6')
      },
      swipeRight() {
       this.$router.push('/bikeTutorial/4')
      }
    }
  }
</script>

<style scoped>

.image {
  border-radius: 0.5rem;
}

</style>